import React from "react";
import { Carousel } from 'react-responsive-carousel';
import { MdAirlineSeatIndividualSuite } from "react-icons/md";
import { GiBarbecue, GiHomeGarage} from "react-icons/gi";
import { FaBath, FaTv, FaWifi, FaRuler, FaTree, FaUmbrellaBeach} from "react-icons/fa";
import { CgSmartHomeRefrigerator } from "react-icons/cg";

import img1 from '../../images/casa-3-personas/1.jpg'
import img2 from '../../images/casa-3-personas/2.jpg'
import img3 from '../../images/casa-3-personas/3.jpg'
import img4 from '../../images/casa-3-personas/4.jpg'
import img5 from '../../images/casa-3-personas/5.jpg'
import img6 from '../../images/casa-3-personas/6.jpg'
import img7 from '../../images/casa-3-personas/7.jpg'
import img8 from '../../images/casa-3-personas/8.jpg'
import img9 from '../../images/casa-3-personas/9.jpg'
import img10 from '../../images/casa-3-personas/10.jpg'
import img11 from '../../images/casa-3-personas/11.jpg'
import img12 from '../../images/casa-3-personas/12.jpg'
import img13 from '../../images/casa-3-personas/13.jpg'
import img14 from '../../images/casa-3-personas/14.jpg'
import img15 from '../../images/casa-3-personas/15.jpg'
import img16 from '../../images/casa-3-personas/16.jpg'
import img17 from '../../images/casa-3-personas/17.jpg'
import img18 from '../../images/casa-3-personas/18.jpg'
import img19 from '../../images/casa-3-personas/19.jpg'
import img20 from '../../images/casa-3-personas/20.jpg'
import img21 from '../../images/casa-3-personas/22.jpg'
import img22 from '../../images/casa-3-personas/21.jpg'


import "react-responsive-carousel/lib/styles/carousel.min.css"



export const SmallHouse = () => {
  return (
    <>                         
        <div className='contanier casa-container' id='casa-chica'>
                <div className='row casa-row'>

                        <div className='col casa-column-1'>
                            <div className='casa-column-1-wrapper '>
                                <Carousel
                                    infiniteLoop='true'
                                    showThumbs='false'
                                >
                                    <div>
                                        <img src={img1} alt='imagen-1'/>
                                    </div>
                                    <div>
                                        <img src={img2}  alt='imagen-2'/>
                                    </div>
                                    <div>
                                        <img src={img3}   alt='imagen-3'/>
                                    </div>
                                    <div>
                                        <img src={img4}   alt='imagen-4'/>
                                    </div>
                                    <div>
                                        <img src={img5}   alt='imagen-5'/>
                                    </div>
                                    <div>
                                        <img src={img6}   alt='imagen-6'/>
                                    </div>
                                    <div>
                                        <img src={img7}   alt='imagen-7'/>
                                    </div>
                                    <div>
                                        <img src={img8}   alt='imagen-8'/>
                                    </div>
                                    <div>
                                        <img src={img9}   alt='imagen-9'/>
                                    </div>
                                    <div>
                                        <img src={img10}   alt='imagen-10'/>
                                    </div>                                    
                                    <div>
                                        <img src={img11}   alt='imagen-11'/>
                                    </div>                                    
                                    <div>
                                        <img src={img12}   alt='imagen-12'/>
                                    </div>
                                    <div>
                                        <img src={img13} alt='imagen-13'/>
                                    </div>
                                    <div>
                                        <img src={img14}  alt='imagen-14'/>
                                    </div>
                                    <div>
                                        <img src={img15}   alt='imagen-15'/>
                                    </div>
                                    <div>
                                        <img src={img16}   alt='imagen-16'/>
                                    </div>
                                    <div>
                                        <img src={img17}   alt='imagen-17'/>
                                    </div>
                                    <div>
                                        <img src={img18}   alt='imagen-18'/>
                                    </div>
                                    <div>
                                        <img src={img19}   alt='imagen-19'/>
                                    </div>
                                    <div>
                                        <img src={img20}   alt='imagen-20'/>
                                    </div>
                                    <div>
                                        <img src={img21}   alt='imagen-21'/>
                                    </div>
                                    <div>
                                        <img src={img22}   alt='imagen-22'/>
                                    </div>
                                </Carousel>
                            </div>
                        </div>

                        <div className='col casa-column-2'>
                            <div className='casa-column-2-items-wrap'> 

                                <div className='casa-column-2-title-wrap'>
                                    <p className='casa-column-2-up-title'>Casa Fondo: para 6 personas</p>                                                                               
                                </div> 

                                <div className='casa-column-2-item'>
                                    <MdAirlineSeatIndividualSuite className='casa-column-2-icon'/>                          
                                    <h6 className='casa-column-2-item-title'>2 Dormitorios</h6>
                                </div> 

                                <div className='casa-column-2-item'>
                                    <FaBath className='casa-column-2-icon'/>                          
                                    <h6 className='casa-column-2-item-title'>2 Baños <small>(1 arriba y 1 abajo)</small> </h6>
                                </div>

                                <div className='casa-column-2-item'>
                                    <FaWifi className='casa-column-2-icon'/>                          
                                    <h6 className='casa-column-2-item-title'> Internet WiFi</h6>
                                </div> 

                                <div className='casa-column-2-item'>
                                    <FaTv className='casa-column-2-icon'/>                          
                                    <h6 className='casa-column-2-item-title'>Smart Tv</h6>
                                </div> 

                                <div className='casa-column-2-item'>
                                    <FaRuler className='casa-column-2-icon'/>                          
                                    <h6 className='casa-column-2-item-title'>120 metros cuadrados</h6>
                                </div> 

                                <div className='casa-column-2-item'>
                                    <GiBarbecue className='casa-column-2-icon'/>                          
                                    <h6 className='casa-column-2-item-title'>Parrilla <small>(Espacio techado para comer afuera)</small></h6>
                                </div> 

                                <div className='casa-column-2-item'>
                                    <CgSmartHomeRefrigerator className='casa-column-2-icon'/>                          
                                    <h6 className='casa-column-2-item-title'>Heladera con freezer</h6>
                                </div> 

                                <div className='casa-column-2-item'>
                                    <GiHomeGarage className='casa-column-2-icon'/>                          
                                    <h6 className='casa-column-2-item-title'>Garage techado</h6>
                                </div> 

                                <div className='casa-column-2-item'>
                                    <FaTree className='casa-column-2-icon'/>                          
                                    <h6 className='casa-column-2-item-title'>Parque de 15m x 40m</h6>
                                </div>
                                
                                <div className='casa-column-2-item'>
                                    <FaUmbrellaBeach className='casa-column-2-icon'/>                          
                                    <h6 className='casa-column-2-item-title'>A 4 cuadras de la playa</h6>
                                </div> 

                                

                            </div>
                        </div>


                </div>
        </div>  

</>
  );
};
