import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const Map = (props) => {
    return (
        <GoogleMap
            defaultZoom={16}
            defaultCenter={ {lat:-36.607158867668716, lng:-56.691564346367784 } }
        >
            {props.isMarkerShown && <Marker position={{ lat: -36.607158867668716, lng: -56.691564346367784  }} />}
        </GoogleMap>
        

    )
}


export default withScriptjs (
    withGoogleMap (
        Map
    )
)